import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Button } from "../../atoms/button"

interface Props {
  children: any
  fluid: any
  link: string
}

export const SlimPackage: React.FC<Props> = (props): JSX.Element => {
  const { children, fluid, link } = props

  return (
    <>
      <Container>
        <FluidImg fluid={fluid} style={{ backgroundSize: "contain", marginBottom: 0, width: "200px", height: "200px" }} />
        {children}
        <Link to={"/packages/" + link} style={{width:"100%"}}>
          <StartButton>view packages</StartButton>
        </Link>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  width: 100%;
  height: 100%;
  margin-bottom: 6rem;
  /* justify-content: space-between; */
  @media (min-width: 768px) {
    padding-top: 1rem;
  }
`

const FluidImg = styled(BackgroundImage)`
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
  }
`

const StartButton = styled(Button)`
  justify-self: initial;
  padding: 1.3rem 0;
  width: 60%;
`
