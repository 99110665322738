import * as React from "react"
import { Head } from "../../components/head"
import { Layout } from "../../components/layout"
import { SlimPackage } from "../../components/_packages/slimPackage"
import SEO from "../../components/seo"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { HeaderCenter } from "../../components/shared/type"

const Index = (): JSX.Element => {
  const { seoImg, webdImg, brandingImg, socialImg } = useStaticQuery(
    graphql`
      query {
        webdImg: file(relativePath: { eq: "services/webDev.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seoImg: file(relativePath: { eq: "services/marketingSEO.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brandingImg: file(relativePath: { eq: "services/branding.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialImg: file(relativePath: { eq: "services/socialMedia.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const webdFluid = webdImg.childImageSharp.fluid
  const seoFluid = seoImg.childImageSharp.fluid
  const brandingFluid = brandingImg.childImageSharp.fluid
  const socialFluid = socialImg.childImageSharp.fluid

  return (
    <>
      <SEO title="Our Packages" />
      <Head title={"Our Packages"} />
      <Layout>
          <Packages>
            <SlimPackage fluid={webdFluid} link="/web-design-and-development">
              <Title>
                Web Design & <SBR />
                Development
              </Title>
            </SlimPackage>

            <SlimPackage fluid={seoFluid} link="/digital-marketing-and-seo">
              <Title>
                Digital Marketing
                <SBR /> & SEO
              </Title>
            </SlimPackage>

            <SlimPackage fluid={brandingFluid} link="/brand-development">
              <Title>Brand Development</Title>
            </SlimPackage>

            <SlimPackage
              fluid={socialFluid}
              link="/social-media-and-content-creation"
            >
              <Title>
                Social Media & <SBR />
                Content Creation
              </Title>
            </SlimPackage>
          </Packages>
          </Layout>
    </>
  )
}

const Packages = styled.div`
  grid-column: 1/-1;
  padding: var(--section-padding-mobile);
  > div:nth-child(even) {
    @media (min-width: 768px) {
    }
  }
  
  @media (min-width: 768px) {
    padding: var(--section-padding-desktop);
    display: grid;
    grid-template-columns: repeat(2, minmax(380px, 650px));
    justify-content: center;
    /* grid-gap: 2rem 1rem; */

    > div:nth-child(even) {
      background-color: none;
    }
  }
`

const SBR = styled.br`
  @media (min-width: 768px) {
    display: none;
  }
`

const Title = styled(HeaderCenter)`
  color: var(--brand-green);
  font: 400 1.75rem/2rem var(--display-font);
  margin-bottom: 25px;

  @media (min-width: 768px) {
    font: 400 2rem/2.25rem var(--display-font);
  }
`

export default Index
